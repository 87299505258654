import React, { useState, Fragment } from 'react';
import { Drawer } from 'antd';
import { NavLink } from 'react-router-dom';

const ReportHeader = () => {
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <header className="jr-view-header view-header">
                <div className="web-links">
                    {/* <NavLink to="/reports/safe-report">Safe Report</NavLink> */}
                    <NavLink to="/reports/today-attendance" >Today's Attendance</NavLink>
                    <NavLink to="/reports/first-in-last-out">First In Last Out</NavLink>
                    <NavLink to="/reports/search-employee">Employee</NavLink>
                    {/* <NavLink to="/reports/un-authorized">Unauthorized</NavLink>
                    <NavLink to="/reports/dwell">Dwell</NavLink>
                    <NavLink to="/reports/hours-worked">Hours Worked</NavLink> */}
                    <NavLink to="/reports/today-absentees">Today's Absentees</NavLink>
                    {/* <NavLink to="/reports/emergency-roll-call">Emergency Roll Call</NavLink> */}
                </div>
                <div className="mobile-view-only">
                    <h1 onClick={showDrawer}>Menu</h1>
                    <Drawer className='dra' placement="right" onClose={onClose} open={open}>
                        <ul onClose={onClose}>
                            {/* <NavLink to="/reports/safe-report">Safe Report</NavLink> */}
                            <li> <NavLink to="/reports/today-attendance" >Today's Attendance</NavLink></li>
                            <li><NavLink to="/reports/first-in-last-out">First In Last Out</NavLink></li>
                            <li><NavLink to="/reports/search-employee">Search Employee</NavLink></li>
                            {/* <li><NavLink to="/reports/un-authorized">Unauthorized</NavLink></li>
                            <li><NavLink to="/reports/dwell">Dwell</NavLink></li>
                            <li><NavLink to="/reports/hours-worked">Hours Worked</NavLink></li> */}
                            <li><NavLink to="/reports/today-absentees">Today's Absentees</NavLink></li>
                            {/* <NavLink to="/reports/emergency-roll-call">Emergency Roll Call</NavLink> */}
                        </ul>
                    </Drawer>
                </div>
            </header>
        </Fragment >
    )
}

export default ReportHeader

