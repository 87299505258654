import PrivateNetworkClient from "helpers/privateNetwork";

const DashboardCountData = async () => {
  if (localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await PrivateNetworkClient.get("/dashboard/data");
  return data;
};
const DashboardSiteData = async () => {
  if (localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await PrivateNetworkClient.get("/dashboard/siteData");
  return data;
};
const DashboardDropdownData = async () => {
  if (localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await PrivateNetworkClient.get("/employee/dropdown/dashboard");
  return data;
};

const DashboardMap = async (id) => {
  if (localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await PrivateNetworkClient.get(`/dashboard/getSitesAndFloors?employeeId=${id || ""}`);
  return data;
};

const EmployeeListByGatewaySerialNumber = async (formData) => {
  const { data } = await PrivateNetworkClient.post("/dashboard/employeeList/gateway", formData);
  return data;
}

const AlertData = async () => {
  if (localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await PrivateNetworkClient.get("/unauthorized_alert");
  return data;
};

const RevertAlertData = async (statusId) => {
  const { data } = await PrivateNetworkClient.get(`/unauthorized_alert/revert/${statusId}`);
  return data;
}

const LocationCount = async () => {
  const { data } = await PrivateNetworkClient.get(`/dashboard/zoneEmployeeCount`);
  return data;
}


const DashboardEmployeeData = async (id) => {
  if (localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await PrivateNetworkClient.get(`/dashboard/employeeLocation/${id}`);
  return data;
};


export {
  DashboardCountData,
  DashboardSiteData,
  DashboardDropdownData,
  DashboardMap,
  EmployeeListByGatewaySerialNumber,
  AlertData,
  RevertAlertData,
  LocationCount,
  DashboardEmployeeData
};