import React, { useState, Fragment, useEffect, useRef } from 'react';
import { Drawer, Avatar, Popconfirm, Modal } from 'antd';
import { profile_image_type, singaporeTime } from 'helpers/commonFunctions';
import PrivateImage from 'helpers/PrivateImage';
import Aud from "../../../assets/Alarm 2.mp3";
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useRevertAlert } from 'hooks/dashboard/dashboard';
import Image from 'helpers/Image';
import "./style.css";

const AppHeader = ({ profileData }) => {
    const location = useLocation();
    let navigate = useNavigate();
    const audioRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAlertVisible, setAlertVisible] = useState(false);
    const [statusId, setStatusId] = useState(0);

    // const { data: alert } = useAlertData();
    const { refetch } = useRevertAlert(statusId);
    const alertClassName = isAlertVisible ? 'alert active' : 'alert';

    const playRingtone = () => {
        audioRef?.current?.play();
    };

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setStatusId(alert?.data?.statusId);
        audioRef?.current?.pause();
        if (location.pathname === "/reports/un-authorized" || location.pathname === "/dashboard") {
            navigate("?returncall=Refresh");
        }
    };

    const toggleAlert = () => {
        setAlertVisible(prevState => !prevState);
    };

    const logout = () => {
        localStorage.removeItem('token');
        navigate("/login")
    };

    useEffect(() => {
        setTimeout(() => {
            toggleAlert();
        }, 600);
    })

    // useEffect(() => {
    //     if (alert?.data?.warning === true) {
    //         showModal();
    //         setTimeout(() => {
    //             playRingtone();
    //         }, 500);
    //     }
    // }, [alert])

    useEffect(() => {
        if (statusId !== 0 && statusId !== null) {
            refetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusId])

    useEffect(() => {
        if (alert?.data?.tokenValid === false) {
            // navigate("/login")
            // localStorage.removeItem("token");
        }
    }, [alert])

    useEffect(() => {
        if (localStorage.getItem("token") === null) {
            navigate("/login")
        }
    })

    return (
        <Fragment>
            <header className="jr-view-header">
                <div className="web-logo">
                    <NavLink to="/dashboard">
                        <Image name="log_new.png" className="logo" />
                    </NavLink>
                </div>
                <div className="web-links">
                    <NavLink to="/dashboard"><Image name="one.png" />Dashboard</NavLink>
                    {profileData?.data?.resDto[1]?.actionAddReqDto?.isView === false ? null :
                        <NavLink to="/reports" ><Image name="two.png" />Reports</NavLink>}
                    <NavLink to="/configuration"><Image name="three.png" />Configuration</NavLink>
                    <NavLink to="/admin"><Image name="four.png" />Admin</NavLink>
                </div>
                <div className="mobile-view-only">
                    <h1 onClick={showDrawer}>Menu</h1>
                    <Drawer className='dra' placement="right" onClose={onClose} open={open}>
                        <ul onClose={onClose}>
                            <li><NavLink onClick={onClose} to="/dashboard">Dashboard</NavLink></li>
                            <li><NavLink onClick={onClose} to="/reports">Reports</NavLink></li>
                            <li><NavLink onClick={onClose} to="/configuration">Configuration</NavLink></li>
                            <li><NavLink onClick={onClose} to="/admin">Admin</NavLink></li>
                        </ul>
                    </Drawer>
                </div>
                <div className="web-auth">
                    <Popconfirm title={`Are you sure you want to Logout?`}
                        onConfirm={() => logout()}
                        okText="Yes"
                        cancelText="No">
                        <Image name="logout.png" className="logout" />  Log Out
                    </Popconfirm>
                    &nbsp;&nbsp;
                    <NavLink to="/profile" >
                        {profileData !== undefined &&
                            profileData?.data?.profileImageUrl !== null ? (
                            <PrivateImage
                                privateUrl={profileData?.data?.profileImageUrl}
                                skeleton="avatar-header"
                                className="avatar-header"
                                imgType={profile_image_type}
                            />
                        ) : (
                            <Avatar>JS</Avatar>
                        )}
                        &nbsp;&nbsp;&nbsp;<span>{profileData?.data?.firstName || "JA Security"} {profileData?.data?.lastName || ""}</span></NavLink>
                </div>
            </header>
            {/* <Modal className="warning-pop-up" width={1000} title="Unauthorised & Hazardoud zone Alert" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className={alertClassName}> <h1>Warning......</h1></div>
                <div className="common-table pop-table">
                    <table>
                        <thead>
                            <tr>
                                <th>Employee Code</th>
                                <th>Employee Name</th>
                                <th>Zone</th>
                                <th>Reason</th>
                                <th>Date & Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {alert?.data?.unauthorizedAlertResDtos == null || alert?.data?.unauthorizedAlertResDtos === undefined || alert?.data?.unauthorizedAlertResDtos?.length === 0 ? (
                                <div className="loading"> <h4>No data found</h4> </div>
                            ) : alert?.data?.unauthorizedAlertResDtos?.map((item) => (
                                <tr>
                                    <td>{item?.employeeCode}</td>
                                    <td>{item?.employeeName}</td>
                                    <td>{item?.zoneName}</td>
                                    <td>{item?.reason}</td>
                                    <td>{singaporeTime(item?.createdAt, "YYYY-MM-DDTHH:mm:ss.SSSZ")}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <audio ref={audioRef}>
                    <source src={Aud} type="audio/mpeg" />
                </audio>
            </Modal> */}
        </Fragment >
    )
}

export default AppHeader

