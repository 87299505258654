import PrivateNetworkClient from "helpers/privateNetwork";


const EmployeeListing = async (formData) => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await PrivateNetworkClient.post("/getEmployeeList", formData);
  return data;
};

const AddEmployee = async (formData) => {
  const { data } = await PrivateNetworkClient.post("/addEmployee", formData);
  return data;
};

const EditEmployee = async (formData) => {
  const { data } = await PrivateNetworkClient.put("/editEmployee", formData);
  return data;
};

const deleteEmployee = async (id) => {
  const { data } = await PrivateNetworkClient.delete(`/deleteEmployee/${id}`);
  return data;
};

const ZoneListing = async () => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await PrivateNetworkClient.get(`/zone/dropdown`);
  return data;
};

const getZoneForEmployee = async (formData) => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await PrivateNetworkClient.post("/zone_list_for_employee", formData);
  return data;
};

const ActiveOrInactive = async (formData) => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await PrivateNetworkClient.put("/employee/activeOrInactive", formData);
  return data;
}

const DepartmentListing = async () => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await PrivateNetworkClient.get(`/department/dropdown`);
  return data;
};

const DropdownShift = async () => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await PrivateNetworkClient.get(`/all_shifts`);
  return data;
};

const EmployeeCardList = async () => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await PrivateNetworkClient.get(`/tagList/dropdown`);
  return data;
};

const EmployeeCardListForEdit = async (id) => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await PrivateNetworkClient.get(`/get_beacon_for_edit/${id}`);
  return data;
};

const zoneListForEmployee = async (id) => {
  if(localStorage.getItem('token') === null) {
    return null;
  }
  const { data } = await PrivateNetworkClient.get(`/zone_list_for_employee/${id}`);
  return data;
};

export {
  EmployeeListing,
  ZoneListing,
  DepartmentListing,
  AddEmployee,
  EditEmployee,
  DropdownShift,
  deleteEmployee,
  zoneListForEmployee,
  EmployeeCardListForEdit,
  getZoneForEmployee,
  EmployeeCardList,
  ActiveOrInactive
};
